import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fetchBusinessData } from "../../../redux/thunk/business";
import { businessActions } from "../../../redux/slice/business";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Avatar, Box, Button } from "@mui/material";

import "./businessInfo.css";
import UpdateBusinessModal from "./UpdateBusinessModal";
import { BusinessType } from "../../../types/businessTypes";
import { url } from "../../../App";

const BusinessInformation = () => {
  const businessList = useSelector(
    (state: RootState) => state.business.allBusinesses
  );
  const business = useSelector(
    (state: RootState) => state.business.businessDetails
  );

  const dispatch = useDispatch<AppDispatch>();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [businessToModal, setBusinessToModal] = useState<BusinessType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchBusinessData());
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const token = localStorage.getItem("token");

  const removeBusiness = async (id: string) => {
    try {
      const response = await fetch(`${url}/business/delete/${id}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        dispatch(businessActions.deleteBusiness(business._id));

        handleClick();
      } else {
        // Handle error if the request fails
        console.error("Error deleting business");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const updateBusiness = (id: string) => {
    setOpenModal(true);
    const businessToUpdate = businessList.find((item) => item._id === id);
    setBusinessToModal(businessToUpdate);
  };

  return (
    <Box className="business-info-container">
      <h2>Business Information</h2>
      <TableContainer component={Paper} sx={{ maxWidth: "90%" }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>#</b>
              </TableCell>
              <TableCell>
                <b>Image</b>
              </TableCell>
              <TableCell>
                <b>Title</b>
              </TableCell>
              <TableCell>
                <b>Category</b>
              </TableCell>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell>
                <b>Location</b>
              </TableCell>
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>
                <b>Edit</b>
              </TableCell>
              <TableCell>
                <b>Delete</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {businessList.map((business, index) => {
              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={`business-${business._id}`}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Avatar aria-label="business-icon">
                      <img
                        src={business.icon}
                        height={70}
                        alt={business.title}
                      />
                    </Avatar>
                    <img
                      src={business.image}
                      height={70}
                      alt={business.title}
                    />
                  </TableCell>
                  <TableCell>{business.title}</TableCell>
                  <TableCell>{business.category}</TableCell>
                  <TableCell>
                    {business.description && business.description.slice(0, 150)}{" "}
                    ...
                  </TableCell>
                  <TableCell>
                    {business.branches && business.branches.length > 0
                      ? business.branches[0].location
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {business.branches && business.branches.length > 0
                      ? business.branches[0].address
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => updateBusiness(business._id)}>
                      <EditIcon />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => removeBusiness(business._id)}>
                      <DeleteIcon color="error" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <UpdateBusinessModal
        open={openModal}
        setOpenModal={setOpenModal}
        businessToModal={businessToModal}
      />
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Business removed successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BusinessInformation;
