import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useLanguage } from "../../components/languages/LanguageContext";
import {
  NAVBAR_HOME_EN,
  NAVBAR_HOME_SE,
  NAVBAR_BUSINESS_EN,
  NAVBAR_BUSINESS_SE,
  NAVBAR_FAVORITE_EN,
  NAVBAR_FAVORITE_SE,
  NAVBAR_SIGN_IN_EN,
  NAVBAR_SIGN_IN_SE,
  NAVBAR_ADD_SE,
  NAVBAR_ADD_EN,
  NAVBAR_CONTACT_SE,
  NAVBAR_CONTACT_EN,
} from "../../constants/textConstants";
import "./navbar.css";
import {
  navBarContainer,
  logoIcon,
  navbarBTN,
  homeBTNnavbar,
} from "../../constants/styleConstants";
import logo from "../../assets/logo1.png";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

function NavBar(props: Props) {
  const business = useSelector(
    (state: RootState) => state.business.businessDetails
  );
  const businesses = useSelector(
    (state: RootState) => state.business.businesses
  );
  const user = useSelector((state: RootState) => state.user.user);
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  const favState = useSelector(
    (state: RootState) => state.user.user?.favoriteBusinesses
  );

  const userId = user?._id ?? "";

  const LoginBTN = styled(Button)({
    textDecoration: "none",
    color: "#3a3939",
    border: "1px solid grey",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
      border: "1px solid black",
    },
  });

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.error.main,
    },
  }));

  const linkStyle = {
    textDecoration: "none",
    color: "#201f1f",
  };
  const linkStyleButton = {
    textDecoration: "none",
    color: "#201f1f",
    padding: "6px",
    border: "1px solid grey",
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const { currentLanguage, toggleLanguage } = useLanguage();

  const drawer = (
    <Box
      style={{ height: "100%" }}
      onClick={handleDrawerToggle}
      className="nav-container"
    >
      <Typography
        className="logo-first"
        textAlign="center"
        variant="h6"
        sx={{ my: 2 }}
      >
        <img width="100px" src={logo} alt="logo" />
      </Typography>
      <Divider />

      <List style={{ paddingLeft: "30%" }}>
        <Link style={linkStyle} to={isLogin ? "/user" : "/login"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <StyledBadge
                  badgeContent={isLogin && user && user.isAdmin ? "A" : null}
                  color="info"
                >
                  <LoginBTN>
                    {isLogin
                      ? user.firstName
                      : currentLanguage === "se"
                      ? NAVBAR_SIGN_IN_SE
                      : NAVBAR_SIGN_IN_EN}
                  </LoginBTN>
                </StyledBadge>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>

        <Link style={linkStyle} to="/">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                {currentLanguage === "se" ? NAVBAR_HOME_SE : NAVBAR_HOME_EN}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link style={linkStyle} to="/business">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                {currentLanguage === "se"
                  ? NAVBAR_BUSINESS_SE
                  : NAVBAR_BUSINESS_EN}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link style={linkStyle} to="/business/66217248fb11b2bbfe89f6df">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                {currentLanguage === "se" ? NAVBAR_ADD_SE : NAVBAR_ADD_EN}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <Link style={linkStyle} to={`/favorites/${userId}`}>
          <StyledBadge
            badgeContent={isLogin ? favState.length : 0}
            color="error"
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText>
                  {currentLanguage === "se"
                    ? NAVBAR_FAVORITE_SE
                    : NAVBAR_FAVORITE_EN}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </StyledBadge>
        </Link>

        <Link style={linkStyle} to="/contact">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                {currentLanguage === "se"
                  ? NAVBAR_CONTACT_SE
                  : NAVBAR_CONTACT_EN}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </Link>
        <ListItem sx={{ width: "100px" }} disablePadding>
          <ListItemButton onClick={toggleLanguage}>
            <ListItemText
              sx={{ borderBottom: "1px solid grey", textAlign: "center" }}
            >
              {currentLanguage === "se" ? "EN" : "SE"}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={navBarContainer}>
      <Toolbar sx={{ width: "100%", display: "flex", margin: "auto" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box className="first-box" sx={homeBTNnavbar}>
          <Link style={linkStyle} to="/">
            {currentLanguage === "se" ? NAVBAR_HOME_SE : NAVBAR_HOME_EN}
          </Link>
          <Link style={linkStyle} to="/business">
            {currentLanguage === "se" ? NAVBAR_BUSINESS_SE : NAVBAR_BUSINESS_EN}
          </Link>

          <Link style={linkStyle} to={`/favorites/${userId}`}>
            <StyledBadge
              badgeContent={isLogin ? favState.length : 0}
              color="error"
            >
              <IconButton>
                <FavoriteBorderIcon />
              </IconButton>
            </StyledBadge>
          </Link>
        </Box>
        <Box className="second-box" sx={logoIcon}>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <Box className="navbar-center">
              <img width="200px" src={logo} alt="logo" />
            </Box>
          </Link>
        </Box>
        <Box className="third-box" sx={navbarBTN}>
          <Link style={linkStyle} to={isLogin ? "/user" : "/login"}>
            <StyledBadge
              badgeContent={isLogin && user && user.isAdmin ? "A" : null}
              color="info"
            >
              <LoginBTN>
                {isLogin
                  ? user.firstName
                  : currentLanguage === "se"
                  ? NAVBAR_SIGN_IN_SE
                  : NAVBAR_SIGN_IN_EN}
              </LoginBTN>
            </StyledBadge>
          </Link>
          <Button
            onClick={toggleLanguage}
            variant="outlined"
            sx={linkStyleButton}
          >
            {currentLanguage === "se" ? "EN" : "SE"}
          </Button>
        </Box>
      </Toolbar>
      <Box component="nav" sx={{ flexGrow: 1, overflowY: "auto" }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default NavBar;
