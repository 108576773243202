// import React, { useEffect } from "react";

// // Redux
// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch, RootState } from "../../redux/store";
// import { fetchBusinessData } from "../../redux/thunk/business";
// import { searchActions } from "../../redux/slice/search";

// // Types
// import { BusinessType } from "../../types/businessTypes";

// // Material UI
// import { Box, Container, Typography } from "@mui/material";

// // Components
// import BusinessItem from "./BusinessItem";
// import SearchBar from "../search/SearchBar";
// import { useLanguage } from "../../components/languages/LanguageContext";

// // Constants
// import {
//   businessListItemContainerStyle,
//   loadingBoxStyle,
//   businessListContainerStyle,
//   introTextStyle,
// } from "../../constants/styleConstants";
// import {
//   BUSINESS_PAGE_WELCOME,
//   BUSINESS_PAGE_WELCOME_SE,
// } from "../../constants/textConstants";

// function BusinessList() {
//   const dispatch = useDispatch<AppDispatch>();
//   const { currentLanguage } = useLanguage(); // Use the useLanguage hook

//   const businessList = useSelector(
//     (state: RootState) => state.business.allBusinesses
//   );
//   const businessSearchList = useSelector(
//     (state: RootState) => state.search.businessSearchResultList
//   );

//   useEffect(() => {
//     dispatch(fetchBusinessData());
//     // Clear search results when the component mounts or unmounts
//     return () => {
//       dispatch(searchActions.setBusinessSearchResults([]));
//     };
//   }, [dispatch]);

//   // Function to move the "ADD NEW STORE" item to the first place
//   const moveItemToFirstPlace = (
//     list: BusinessType[],
//     title: string
//   ): BusinessType[] => {
//     const index = list.findIndex((item) => item.title === title);
//     if (index > -1) {
//       const [item] = list.splice(index, 1);
//       list.unshift(item);
//     }
//     return list;
//   };

//   // Apply the reordering logic
//   const orderedBusinessList = moveItemToFirstPlace(
//     [...businessList],
//     "Add New"
//   );
//   const orderedBusinessSearchList = moveItemToFirstPlace(
//     [...businessSearchList],
//     "Add New"
//   );

//   return (
//     <Box sx={businessListContainerStyle}>
//       <Box>
//         <SearchBar />
//       </Box>
//       <Box sx={{ marginBlock: "50px" }}>
//         <Typography variant="h4" sx={introTextStyle}>
//           {currentLanguage === "se"
//             ? BUSINESS_PAGE_WELCOME_SE
//             : BUSINESS_PAGE_WELCOME}
//         </Typography>
//       </Box>
//       <Box className="business-list" sx={businessListItemContainerStyle}>
//         {orderedBusinessList.length > 0 ? (
//           orderedBusinessSearchList.length !== 0 ? (
//             orderedBusinessSearchList.map((item: BusinessType) => (
//               <Box key={item._id}>
//                 <BusinessItem business={item} />
//               </Box>
//             ))
//           ) : (
//             orderedBusinessList.map((item: BusinessType) => (
//               <Box key={item._id}>
//                 <BusinessItem business={item} />
//               </Box>
//             ))
//           )
//         ) : (
//           <Box className="loading" sx={loadingBoxStyle}>
//             <i className="fas fa-spinner fa-spin fa-xl" />
//             <em>No results found</em>
//           </Box>
//         )}
//       </Box>
//     </Box>
//   );
// }

// export default BusinessList;

import React, { useEffect, useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchBusinessData } from "../../redux/thunk/business";
import { searchActions } from "../../redux/slice/search";

// Types
import { BusinessType } from "../../types/businessTypes";

// Material UI
import { Box, Container, Typography, IconButton } from "@mui/material";

// Components
import BusinessItem from "./BusinessItem";
import SearchBar from "../search/SearchBar";
import { useLanguage } from "../../components/languages/LanguageContext";

// Constants
import {
  businessListItemContainerStyle,
  loadingBoxStyle,
  businessListContainerStyle,
  introTextStyle,
} from "../../constants/styleConstants";
import {
  BUSINESS_PAGE_WELCOME,
  BUSINESS_PAGE_WELCOME_SE,
} from "../../constants/textConstants";

// Import the scroll-to-top arrow image
import arrowImg from "../../assets/arrow.png";

function BusinessList() {
  const dispatch = useDispatch<AppDispatch>();
  const { currentLanguage } = useLanguage(); // Use the useLanguage hook

  const businessList = useSelector(
    (state: RootState) => state.business.allBusinesses
  );
  const businessSearchList = useSelector(
    (state: RootState) => state.search.businessSearchResultList
  );

  const [showScrollArrow, setShowScrollArrow] = useState(false);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Effect to track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowScrollArrow(true);
      } else {
        setShowScrollArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchBusinessData());
    // Clear search results when the component mounts or unmounts
    return () => {
      dispatch(searchActions.setBusinessSearchResults([]));
    };
  }, [dispatch]);

  // Function to move the "ADD NEW STORE" item to the first place
  const moveItemToFirstPlace = (
    list: BusinessType[],
    title: string
  ): BusinessType[] => {
    const index = list.findIndex((item) => item.title === title);
    if (index > -1) {
      const [item] = list.splice(index, 1);
      list.unshift(item);
    }
    return list;
  };

  // Apply the reordering logic
  const orderedBusinessList = moveItemToFirstPlace(
    [...businessList],
    "Add New"
  );
  const orderedBusinessSearchList = moveItemToFirstPlace(
    [...businessSearchList],
    "Add New"
  );

  return (
    <Box sx={businessListContainerStyle}>
      <Box>
        <SearchBar />
      </Box>
      <Box sx={{ marginBlock: "50px" }}>
        <Typography variant="h4" sx={introTextStyle}>
          {currentLanguage === "se"
            ? BUSINESS_PAGE_WELCOME_SE
            : BUSINESS_PAGE_WELCOME}
        </Typography>
      </Box>
      <Box className="business-list" sx={businessListItemContainerStyle}>
        {orderedBusinessList.length > 0 ? (
          orderedBusinessSearchList.length !== 0 ? (
            orderedBusinessSearchList.map((item: BusinessType) => (
              <Box key={item._id}>
                <BusinessItem business={item} />
              </Box>
            ))
          ) : (
            orderedBusinessList.map((item: BusinessType) => (
              <Box key={item._id}>
                <BusinessItem business={item} />
              </Box>
            ))
          )
        ) : (
          <Box className="loading" sx={loadingBoxStyle}>
            <i className="fas fa-spinner fa-spin fa-xl" />
            <em>No results found</em>
          </Box>
        )}
      </Box>

      {/* Arrow for scrolling back to the top */}
      {showScrollArrow && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            width: "60px",
            height: "80px",
            backgroundColor: "transparent",
            padding: 0, // Remove any default padding for the icon button
            "&:hover": {
              transform: "scale(1.1)", // Slight scale-up on hover
            },
            "&:focus": {
              outline: "none", // Remove the default focus outline
              boxShadow: "none", // Remove any default box-shadow (if applied)
            },
          }}
        >
          <img
            src={arrowImg} // Use the imported image
            alt="Scroll to top"
            style={{
              width: "40px", // Adjust width and height as necessary
              height: "auto",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}

export default BusinessList;
