import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  CardMedia,
  Avatar,
  Box,
  Button,
  Card,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
} from "@mui/material";
import { commentActions } from "../../redux/slice/comment";
import { Form, Formik, FormikHelpers } from "formik";
import axios from "axios";
import { RootState, AppDispatch } from "../../redux/store";
import { LuSendHorizonal } from "react-icons/lu";
import { formatDistanceToNow } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import { MdDelete, MdOutlinePhotoCamera } from "react-icons/md";
import "./comment.css";
import UpdateSubCommentModal from "./updateSubcomment/UpdateSubCommentModal";
import { SubCommentType } from "../../types/subCommentTypes";
import { CommentType } from "../../types/commentTypes";
import { url } from "../../App";
import subCommentSchema from "./subCommentSchema";

import {
  subCommentCard,
  subCommentEditButton,
  subCommentItem,
  subCommentViewMoreButton,
} from "../../constants/styleConstants";
import imageCompression from "browser-image-compression";

type SubCommentFormValues = {
  text: string;
  rate: number | null;
  imageUrl?: string | null;
};

const initialSubCommentFormValues: SubCommentFormValues = {
  text: "",
  rate: null,
  imageUrl: null,
};

type PropType = {
  comment: CommentType;
};

const SubComment = ({ comment }: PropType) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [loginAlertOpen, setLoginAlertOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showSubcomments, setShowSubcomments] = useState(false);
  const [imageSizeError, setImageSizeError] = useState(false);

  const user = useSelector((state: RootState) => state.user.user);
  //-------------update comment----

  const [openModal, setOpenModal] = useState(false);
  const [subCommentToModal, setSubCommentToModal] = useState<SubCommentType>();
  const subComments = useSelector(
    (state: RootState) => state.subComment.subComments
  );

  const isLoggedIn = useSelector((state: RootState) => state.user.isLogin);
  type AlertState = {
    message: string;
    severity: "success" | "error" | "warning" | "info";
  };

  const [alert, setAlert] = useState<AlertState>({
    message: "",
    severity: "success",
  });
  const dispatch = useDispatch<AppDispatch>();

  const toggleSubcomments = () => {
    setShowSubcomments((prev) => !prev);
  };

  const handleButtonClick = () => {
    // Show the form when the button is clicked
    setIsFormVisible((prevValue) => !prevValue);
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const token = localStorage.getItem("token");

  const handleLoginAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setLoginAlertOpen(false);
  };

  const handleLoginAlertOpen = () => {
    setLoginAlertOpen(true);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Options for image compression
      const options = {
        maxSizeMB: 1, // Max file size in MB (100 KB)
        maxWidthOrHeight: 300, // Max width or height of the output image in pixels
        useWebWorker: true, // Use web worker for faster compression
      };

      try {
        const compressedFile = await imageCompression(file, options);
        setImageSizeError(false); // Reset imageSizeError
        setSelectedFile(compressedFile); // Set the compressed file as selectedFile
      } catch (error) {
        console.error("Image compression failed:", error);
        setImageSizeError(true); // Set imageSizeError if compression fails
        setSelectedFile(null); // Clear selectedFile
      }
    }
  };

  const submitSubcommentHandler = async (
    values: SubCommentFormValues,
    { resetForm }: FormikHelpers<SubCommentFormValues>
  ) => {
    if (!user || !token) {
      setAlert({
        message: "Please log in to submit a subcomment",
        severity: "warning",
      });
      handleLoginAlertOpen();
      return;
    }
    //setSubCommentIsSavingRate
    try {
      const subCommentData = new FormData();
      subCommentData.append("text", values.text);

      if (values.rate) {
        subCommentData.append("rate", String(values.rate));
      }
      if (user && user._id) {
        subCommentData.append("userId", user._id);
        subCommentData.append("firstName", user.firstName);
        subCommentData.append("lastName", user.lastName);
        subCommentData.append("userImage", user.image);
      }

      if (selectedFile) {
        subCommentData.append("image", selectedFile);
      }

      const response = await axios.post(
        `${url}/comment/addSubComment/${comment._id}`,
        subCommentData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Change to multipart/form-data for file upload
          },
        }
      );
      if (response.status === 201) {
        dispatch(
          commentActions.addSubcomments({
            commentId: comment._id, // include the commentId in the payload
            subcomments: [response.data],
          })
        );

        resetForm({ values: initialSubCommentFormValues });
        setSelectedFile(null);
        setIsFormVisible(false);

        // Set showSubcomments to true after adding a new subcomment
        setShowSubcomments(true);
      }
    } catch (error) {
      console.error("Error submitting subcomment:", error);
    }
  };

  const updateSubComment = (id: string) => {
    setOpenModal(true);
    const subCommentToUpdate = subComments.find((item) => item._id === id);
    setSubCommentToModal(subCommentToUpdate);
  };

  const deleteSubComment = async (
    subCommentId: string,
    userId: string | undefined
  ) => {
    try {
      if (!userId) {
        console.error("Unauthorized: User not authenticated");
        return; // Exit the function if user is not authenticated
      }

      // Perform delete request to delete the subcomment
      const response = await fetch(`${url}/subComment/delete/${subCommentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 404) {
        // Handle case where sub-comment was not found
        console.error("Sub-comment not found");
        return;
      }

      if (response.ok) {
        // If deletion is successful, update the state to remove the deleted subcomment

        dispatch(commentActions.deleteSubComment({ subCommentId }));
      } else {
        const errorMessage = await response.text();
        console.error("Error deleting subcomment:", errorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        <Box
          sx={{
            width: "100%",
            InfinitymarginLeft: "auto",
            position: "relative",
          }}
        >
          <Formik
            initialValues={initialSubCommentFormValues}
            validationSchema={subCommentSchema}
            onSubmit={submitSubcommentHandler}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <TextField
                  className="textBox"
                  label={`Answer as ${user.firstName} ${user.lastName} `}
                  name="textReply"
                  multiline
                  maxRows={10} // Allow it to grow as needed
                  onChange={(e) =>
                    handleChange({
                      target: { name: "text", value: e.target.value },
                    })
                  }
                  value={values.text}
                  sx={{
                    paddingBottom: "20px",
                    background: "#e0e0e0",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "20px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      "& fieldset": {
                        border: "none", // Remove the initial border
                      },
                    },
                  }}
                  error={touched.text && Boolean(errors.text)}
                  helperText={touched.text && errors.text}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          gap: "20px",
                          position: "absolute",
                          bottom: "5px",
                          right: "10px",
                        }}
                      >
                        <Box
                          className="subComment-submit-box"
                          sx={{ paddingBottom: "10px", marginTop: "15px" }}
                        >
                          <Button
                            title="Attach Picture"
                            sx={{
                              color: "grey",
                              minWidth: "20px",
                              fontSize: {
                                xs: "1em",
                                sm: "1em",
                                md: "1.5em",
                              },
                              cursor: "pointer",
                            }}
                          >
                            <MdOutlinePhotoCamera
                              onClick={() => inputRef.current?.click()}
                            />
                          </Button>
                          <input
                            ref={inputRef}
                            type="file"
                            name="image"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                          />
                          <Button
                            type="submit"
                            sx={{
                              color: "grey",
                              minWidth: "20px",
                              fontSize: {
                                xs: "1em",
                                sm: "1em",
                                md: "1.5em",
                              },
                              cursor: "pointer",
                            }}
                            title="Submit Comment"
                          >
                            <LuSendHorizonal />
                          </Button>
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Form>
            )}
          </Formik>
        </Box>

        <Box sx={subCommentItem}>
          {comment.subComments && comment.subComments.length > 0 && (
            <Box key={comment._id}>
              <Button onClick={toggleSubcomments} sx={subCommentViewMoreButton}>
                {showSubcomments ? "Hide Replies" : "View more Replies"}
              </Button>
              {showSubcomments && (
                <>
                  {comment.subComments.map((subcomment) => (
                    <Card key={subcomment._id} sx={subCommentCard}>
                      {user && (
                        <Box>
                          <Box sx={subCommentEditButton}>
                            {user._id === subcomment?.userId && (
                              <Button
                                title="Edit"
                                sx={{
                                  color: "grey",
                                  minWidth: "20px",
                                  fontSize: {
                                    xs: "1em",
                                    sm: "1em",
                                    md: "1.5em",
                                  },
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  subcomment &&
                                  subcomment._id &&
                                  updateSubComment(subcomment._id)
                                }
                              >
                                <EditIcon />
                              </Button>
                            )}
                            {subcomment._id &&
                              subcomment.userId === user._id && (
                                <Button
                                  title="Delete"
                                  sx={{
                                    color: "grey",
                                    minWidth: "20px",
                                    fontSize: {
                                      xs: "1em",
                                      sm: "1em",
                                      md: "1.5em",
                                    },
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    deleteSubComment(subcomment._id!, user._id)
                                  }
                                >
                                  <MdDelete size={30} />
                                </Button>
                              )}
                          </Box>
                          <Box sx={{ display: "flex", marginTop: "20px" }}>
                            <Box>
                              <Avatar
                                aria-label="user"
                                sx={{
                                  width: {
                                    xs: "50px",
                                    sm: "50px",
                                    md: "70px",
                                  },
                                  height: {
                                    xs: "50px",
                                    sm: "50px",
                                    md: "70px",
                                  },
                                }}
                              >
                                <img
                                  src={subcomment.userImage}
                                  alt={subcomment.firstName}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                  }}
                                />
                              </Avatar>
                            </Box>
                            <Box
                              sx={{ marginBlock: "auto", paddingLeft: "10px" }}
                            >
                              <Typography variant="h6">
                                {subcomment.firstName} {subcomment.lastName}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      )}

                      <Box
                        sx={{
                          width: "80%",
                          marginLeft: "auto",
                          marginBlock: "20px",
                        }}
                      >
                        <Typography textAlign={"left"}>
                          {subcomment.text}
                        </Typography>
                      </Box>

                      {subcomment?.image && (
                        <Box>
                          <CardMedia
                            component="img"
                            width="200"
                            height="200"
                            image={subcomment.image}
                            alt="Subcomment Image"
                            sx={{ objectFit: "contain" }}
                          />
                        </Box>
                      )}

                      <Box sx={{ textAlign: "end" }}>
                        <Typography variant="caption">
                          {subcomment.date
                            ? formatDistanceToNow(new Date(subcomment.date), {
                                addSuffix: true,
                              })
                            : "Date Unavailable"}
                        </Typography>
                      </Box>
                    </Card>
                  ))}
                </>
              )}
            </Box>
          )}
        </Box>

        {comment.subComments &&
          comment.subComments.map((subComment) => (
            <UpdateSubCommentModal
              key={`${comment._id}-${subComment._id}`}
              open={openModal}
              setOpenModal={setOpenModal}
              subCommentToModal={subComment}
              userId={user._id}
            />
          ))}
        <Snackbar
          open={loginAlertOpen}
          autoHideDuration={3000}
          onClose={handleLoginAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleLoginAlertClose}
            severity="error"
            sx={{ width: "300px" }}
          >
            Please log in in order to leave a comment
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default SubComment;
