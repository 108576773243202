import React, { useState } from "react";

// React Router
import { Link } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../redux/slice/user";
import { RootState } from "../../redux/store";

// Types
import { BusinessType } from "../../types/businessTypes";

// Material UI
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Rating,
  Typography,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

// Constants
import {
  cardAvatarImageStyle,
  cardAvatarStyle,
  cardStyle,
} from "../../constants/styleConstants";

// App URL
import { url } from "../../App";

type PropType = {
  business: BusinessType;
};

function BusinessItem({ business }: PropType) {
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const dispatch = useDispatch();
  const favState = useSelector(
    (state: RootState) => state.user.user.favoriteBusinesses
  );
  const [isFav, setIsFav] = useState(
    favState.some((fav) => fav._id === business._id)
  );
  const isLogin = useSelector((state: RootState) => state.user.isLogin);
  const user = useSelector((state: RootState) => state.user.user);
  const token = localStorage.getItem("token");

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason !== "clickaway") {
      setOpen(false);
    }
  };

  const toggleFavorite = async () => {
    if (!isLogin) {
      setNotificationMessage("Please log in to toggle favorite status");
      setOpen(true);
      return;
    }

    const newFavStatus = !isFav;
    setIsFav(newFavStatus);
    const urlPath = newFavStatus
      ? `${url}/users/addFavorite/${user._id}/${business._id}`
      : `${url}/users/removeFavorite/${user._id}/${business._id}`;

    const methodType = newFavStatus ? "POST" : "DELETE";

    try {
      const response = await fetch(urlPath, {
        method: methodType,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body:
          methodType === "POST"
            ? JSON.stringify({ userId: user._id, businessId: business._id })
            : undefined,
      });

      if (response.ok) {
        const responseData = await response.json();
        dispatch(
          newFavStatus
            ? userActions.addFavoriteBusiness(responseData)
            : userActions.removeFavoriteBusiness(responseData)
        );
        //setIsFav(newFavStatus);
        setNotificationMessage(
          `Favorite ${newFavStatus ? "added" : "removed"} successfully`
        );
        setOpen(true);
      } else {
        const errorData = await response.json();
        console.error("Error toggling favorite:", errorData);
        dispatch(
          userActions.errorHandler(
            `Failed to ${newFavStatus ? "add" : "remove"} favorite business`
          )
        );
        //setIsFav(!newFavStatus);
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
      dispatch(
        userActions.errorHandler(
          `Failed to ${
            newFavStatus ? "add" : "remove"
          } favorite business due to network error`
        )
      );
      //  setIsFav(!newFavStatus);
    }
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SnackbarContent message={notificationMessage} />
      </Snackbar>

      <Box>
        <Card
          style={cardStyle}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.02)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
          }}
        >
          <Link
            to={`/business/${business._id}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            <Box style={{ position: "relative" }}>
              <Box aria-label="image" sx={cardAvatarStyle}>
                <img
                  src={business.icon}
                  style={cardAvatarImageStyle}
                  alt={business.title}
                />
              </Box>

              <CardMedia
                component="img"
                height="240"
                image={business.image}
                alt={business.title}
              />
            </Box>
            <CardContent style={{ padding: "1px" }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{
                  fontSize: "0.875rem",
                }}
              >
                {business.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {business.description.length > 30
                  ? `${business.description.slice(0, 30)}...`
                  : business.description}
              </Typography>

              <Typography
                gutterBottom
                variant="body2"
                component="div"
                color="text.secondary"
              >
                {business.category}
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                <Rating
                  style={{ marginLeft: "5%" }}
                  name="read-only"
                  value={business.rate}
                  readOnly
                />
              </Typography>
            </CardContent>
          </Link>
          <Box>
            <Button title="Favorites" onClick={toggleFavorite}>
              <FavoriteBorderIcon sx={{ color: isFav ? "red" : "gray" }} />
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
}

export default BusinessItem;
