import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Typography } from "@mui/material";
import "./footer.css";
import { Link } from "react-router-dom";
import {
  footerColumnOne,
  footerColumns,
  footerTitle,
  linkStyle,
  logoIcon,
} from "../../constants/styleConstants";
import logo from "../../assets/logo1.png";

function Footer() {
  return (
    <Box
      style={{
        width: "90%",
        marginInline: "auto",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",

          paddingBottom: "20px",
          marginBottom: "20px",
          borderBottom: "1px solid black",
        }}
        className="footer-icon"
      >
        <Box sx={footerColumnOne}>
          <Link to="/" style={{ textDecoration: "none", color: "black" }}>
            <Box className="navbar-center" sx={logoIcon}>
              <img width="200px" src={logo} alt="logo" />
            </Box>
          </Link>
        </Box>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
        className="footer-section1"
      >
        <Box className="footer-column" sx={footerColumns}>
          <Box sx={footerColumnOne}>
            <Typography sx={footerTitle}>Features</Typography>
            <Link to="/about" style={linkStyle}>
              About Us
            </Link>
            <Link to="/contact" style={linkStyle}>
              Contact Us
            </Link>
            <Box>
              <InstagramIcon />
              <a
                href="https://www.facebook.com/shoutitout.se/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.linkedin.com/in/alina-samoteev-627836a5/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </Box>
          </Box>

          <Box sx={footerColumnOne}>
            <Typography sx={footerTitle}>Company</Typography>
            <Link to="/business" style={linkStyle}>
              All Stores
            </Link>
            <Link to="/registration" style={linkStyle}>
              Register
            </Link>
            <Link to="/login" style={linkStyle}>
              Log In
            </Link>
          </Box>

          <Box sx={footerColumnOne}>
            <Typography sx={footerTitle}>Legal</Typography>
            <Link to="/terms-of-use" style={linkStyle}>
              Terms of Use
            </Link>
            <Link to="/policy" style={linkStyle}>
              Policy
            </Link>
            <Link to="/policy#cookies" style={linkStyle}>
              Cookie Policy
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        style={{ paddingTop: "40px", paddingBottom: "10px" }}
        className="footer-section2"
      >
        <Box
          className="footer-copyright"
          sx={{ textDecoration: "none", color: "inherit" }}
        >
          <a
            href="https://www.samoteev.dev/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            2024&#169; Shout It Out
          </a>
        </Box>
      </Box>
      {/* <Typography sx={introWelcome}>{INTRO_WELCOME}</Typography> */}
    </Box>
  );
}

export default Footer;
