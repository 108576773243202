import { url } from "../../App";
import { AppDispatch } from "../store";
import { businessActions } from "./../slice/business";


export function fetchBusinessDetail(id: string | undefined) {
  return async (dispatch: AppDispatch) => {
    try {
      // Check if 'id' is not undefined before making the fetch request
      if (id) {
        const response = await fetch(`${url}/business/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch business details");
        }
        const data = await response.json();
        // Dispatch the action with the fetched business data
        dispatch(businessActions.getBusinessDetails(data));
      } else {
  
        console.error("Error: Business ID is undefined.");
     
      }
    } catch (error) {
      console.error("Error fetching business details:", error);
 
    }
  };
}
