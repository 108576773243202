import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Button,
  Box,
  Typography,
  Card,
  CardMedia,
  Alert,
  Snackbar,
  Avatar,
  Container,
} from "@mui/material";

import { formatDistanceToNow } from "date-fns";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { MdDelete } from "react-icons/md";
import { RootState, AppDispatch } from "../../redux/store";
import { url } from "../../App";
import { commentActions } from "../../redux/slice/comment";

import UpdateCommentModal from "./updateComments/UpdateCommentModal";
import { UserRating, RatingSummary } from "./UserRate";
import SubComment from "./SubComment";
import {
  cardMediaStyles,
  commentItem,
  commentItemAvatarStyle,
  commentItemBoxRightStyle,
  commentItemBoxStyle,
  commentItemContainerStyle,
  commentItemImageCardStyle,
  commentItemRatingBoxStyle,
  commentItemTextStyle,
  commentItemTimeBoxStyle,
  commentItemUserInfoBoxStyle,
} from "../../constants/styleConstants";
import { CommentType } from "../../types/commentTypes";

type PropType = {
  comment: CommentType;
};

const CommentItemRateFav = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const CommentItem = ({ comment }: PropType) => {
  const location = useLocation();
  const [isClicked, setIsClicked] = useState(false);

  const [showSubcomments, setShowSubcomments] = useState(false);

  const toggleSubcomments = () => {
    setShowSubcomments(!showSubcomments);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  const [commentUserRate, setCommentUserRate] = useState<number | null>(
    comment.rate
  );

  const relativeTime = comment.date
    ? formatDistanceToNow(new Date(comment.date), { addSuffix: true })
    : "Date Unavailable";

  const [open, setOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user.user);
  const currentUserId = useSelector((state: RootState) => state.user.user._id);
  //-------------update comment----

  const commentList = useSelector((state: RootState) => state.comment.comments);

  const [openModal, setOpenModal] = useState(false);
  const [commentToModal, setCommentToModal] = useState<CommentType>();

  //--------update comment end------
  const dispatch = useDispatch<AppDispatch>();

  const token = localStorage.getItem("token");

  type AlertState = {
    message: string;
    severity: "success" | "error" | "warning" | "info";
  };

  const [alert, setAlert] = useState<AlertState>({
    message: "",
    severity: "success",
  });

  const isLoggedIn = useSelector((state: RootState) => state.user.isLogin);

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCommentRateChange = async (newRate: number | null) => {
    try {
      setCommentUserRate(newRate);
      const responseData = await submitRate(newRate);

      // Ensure responseData contains the updated ratings
      if (responseData && responseData.ratings && comment._id) {
        // Dispatch action to update the ratings in the Redux store
        dispatch(
          commentActions.setRatesForComment({
            commentId: comment._id,
            rates: responseData.ratings,
          })
        );
        setAlert({
          message: "Thank you for your rate!",
          severity: "success",
        });
        setOpen(true);
      } else {
        console.error("Invalid responseData format or ratings not found.");
      }
    } catch (error) {
      console.error("Error submitting rate:", error);
      setCommentUserRate(null); // Reset the rate if an error occurs
      setAlert({
        message: "Please log in to rate.",
        severity: "error",
      });
      setOpen(true);
    }
  };

  const submitRate = async (rate: number | null) => {
    if (!isLoggedIn) {
      throw new Error("User is not logged in.");
    }
    if (!comment._id) {
      throw new Error("Comment ID is undefined.");
    }
    if (!currentUserId) {
      throw new Error("Current user ID is undefined.");
    }
    if (rate === commentUserRate) {
      return { rate: commentUserRate }; // Return the current rate
    }

    try {
      const requestBody = JSON.stringify({ rate });

      const response = await fetch(`${url}/comment/addRate/${comment._id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      const responseData = await response.json();

      if (response.ok) {
        setAlert({
          message: "Thank you for your rate!",
          severity: "success",
        });
        setOpen(true);
        return responseData; // Return the response data
      } else {
        throw new Error(responseData.message || "Error adding rate.");
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const text = comment.text;

  const updateComment = (id: string) => {
    setOpenModal(true);
    const commentToUpdate = commentList.find((item) => item._id === id);
    setCommentToModal(commentToUpdate);
  };

  //---------------DELETE FUNCTIONS -----------------

  const deleteComment = async (
    commentId: string,
    userId: string | undefined
  ) => {
    try {
      if (!userId) {
        console.error("Unauthorized: User not authenticated");
        setAlert({
          message: "User is not authenticated",
          severity: "error",
        });
        setOpen(true);
        return; // Exit the function if user is not authenticated
      }

      // Perform delete request to delete the comment
      const response = await fetch(`${url}/comment/delete/${commentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Show the alert first
        setAlert({
          message: "Your comment was removed",
          severity: "success",
        });
        setOpen(true);

        // Delay the dispatch by 2 seconds (2000 ms)
        setTimeout(() => {
          dispatch(commentActions.deleteComment(commentId));
          console.log(alert, "alert works");
        }, 1000);
      } else {
        console.error("Error deleting comment");
        setAlert({
          message: "Failed to delete the comment",
          severity: "error",
        });

        setOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setAlert({
        message: "An error occurred while deleting the comment",
        severity: "error",
      });
      setOpen(true);
    }
  };

  return (
    <Container id={`comment-${comment._id}`} sx={commentItemContainerStyle}>
      <Box>
        <Box sx={commentItemBoxStyle}>
          <Typography textAlign="left" variant="h6">
            {comment.businessLocation
              ? comment.businessLocation.charAt(0).toUpperCase() +
                comment.businessLocation.slice(1).toLowerCase()
              : ""}
          </Typography>
        </Box>
      </Box>
      <Box sx={commentItemBoxRightStyle}>
        <Box
          sx={{
            width: "100px",
            position: "absolute",
            right: "10px",
            top: "-50px",
          }}
        >
          {user._id === comment?.userId && (
            <Button
              title="Edit"
              sx={{
                color: "grey",
                minWidth: "20px",
                fontSize: {
                  xs: "14px",
                  sm: "1em",
                  md: "1.5em",
                },
                cursor: "pointer",
              }}
              onClick={() =>
                comment && comment._id && updateComment(comment._id)
              }
            >
              <EditIcon />
            </Button>
          )}
          {comment._id && comment.userId === user._id && (
            <Button
              title="Delete"
              sx={{
                color: "grey",
                minWidth: "20px",
                fontSize: {
                  xs: "14px",
                  sm: "1em",
                  md: "1.5em",
                },
                cursor: "pointer",
              }}
              onClick={() => deleteComment(comment._id!, user._id)}
            >
              <MdDelete size={30} />
            </Button>
          )}
        </Box>
      </Box>

      <Stack spacing={2}>
        <Box sx={commentItem}>
          {comment.firstName && (
            <Box sx={commentItemUserInfoBoxStyle}>
              <Typography textAlign={"left"} variant="h6">
                {comment.firstName} {comment.lastName}
              </Typography>
            </Box>
          )}

          <Box sx={{ marginTop: "20px" }}>
            <Avatar aria-label="recipe" sx={commentItemAvatarStyle}>
              <img
                src={comment.userImage}
                alt={comment.firstName}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "top", // This ensures the top of the image is shown
                }}
              />
            </Avatar>
          </Box>

          <Box sx={commentItemTextStyle}>
            {text && (
              <Typography
                sx={{
                  fontSize: "14px",
                }}
                textAlign={"start"}
              >
                {text}
              </Typography>
            )}
          </Box>
          {comment.image && (
            <Card sx={commentItemImageCardStyle}>
              <CardMedia
                component="img"
                height="auto"
                image={comment.image}
                alt="Comment Image"
                sx={{
                  ...cardMediaStyles,
                  transform: isClicked ? "scale(3)" : "none",
                }}
                onClick={handleClick}
              />
            </Card>
          )}
          <Box sx={{ width: "150px", position: "absolute" }}>
            <Typography textAlign={"start"}>
              {comment.subComments?.length} replies
            </Typography>
          </Box>

          <Box sx={commentItemRatingBoxStyle}>
            <RatingSummary ratings={comment.ratings || []} />
          </Box>
          <Box sx={commentItemTimeBoxStyle}>{relativeTime}</Box>
        </Box>
      </Stack>

      <CommentItemRateFav>
        <UserRating
          userRate={commentUserRate}
          onRateChange={handleCommentRateChange}
        />
      </CommentItemRateFav>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <Box>
        <SubComment key={comment._id + comment.userId} comment={comment} />
        <UpdateCommentModal
          open={openModal}
          setOpenModal={setOpenModal}
          commentToModal={comment}
          userId={user._id}
        />
      </Box>
    </Container>
  );
};

export default CommentItem;
