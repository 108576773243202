import { RatingType } from "../../types/commentTypes";
import { IconButton, Box, Button, Typography } from "@mui/material";
import angryIcon from "../../assets/anger.png";
import dislikeIcon from "../../assets/dislike.png";
import likeIcon from "../../assets/like.png";
import happyIcon from "../../assets/smile.png";
import heartIcon from "../../assets/love.png";
import angryIconb from "../../assets/anger-black.png";
import dislikeIconb from "../../assets/dislike-black.png";
import likeIconb from "../../assets/like-black.png";
import happyIconb from "../../assets/smile-black.png";
import heartIconb from "../../assets/love-black.png";
import { useState } from "react";

const iconStyles: React.CSSProperties = {
  width: "2rem",
  height: "auto",
};

export const UserRating = ({
  userRate,
  onRateChange,
}: {
  userRate: number | null;
  onRateChange: (value: number | null) => void;
}) => {
  const [showRatingIcons, setShowRatingIcons] = useState(false);

  const handleLikeClick = () => {
    setShowRatingIcons(!showRatingIcons);
  };

  const handleRateChange = (value: number | null) => {
    onRateChange(value);
    setShowRatingIcons(false); // Hide icons after rate is submitted
  };

  return (
    <Box style={{ display: "flex", justifyContent: "center", margin: "1px 0" }}>
      {/* {showRatingIcons ? ( */}
      <>
        <IconButton
          onClick={() => handleRateChange(1)}
          style={{ padding: "5px" }}
        >
          <img src={angryIconb} alt={`Rate 1`} style={iconStyles} />
        </IconButton>
        <IconButton
          onClick={() => handleRateChange(2)}
          style={{ padding: "5px" }}
        >
          <img src={dislikeIconb} alt={`Rate 2`} style={iconStyles} />
        </IconButton>
        <IconButton
          onClick={() => handleRateChange(3)}
          style={{ padding: "5px" }}
        >
          <img src={likeIconb} alt={`Rate 3`} style={iconStyles} />
        </IconButton>
        <IconButton
          onClick={() => handleRateChange(4)}
          style={{ padding: "5px" }}
        >
          <img src={happyIconb} alt={`Rate 4`} style={iconStyles} />
        </IconButton>
        <IconButton
          onClick={() => handleRateChange(5)}
          style={{ padding: "5px", paddingTop: "10px" }}
        >
          <img src={heartIconb} alt={`Rate 5`} style={iconStyles} />
        </IconButton>
      </>
      {/* // ) : (
      //   <Button onClick={handleLikeClick} style={{ padding: 0 }}>
      //     <Typography style={{ marginRight: "auto", fontSize: "16px" }}>
      //       Like
      //     </Typography>
      //   </Button>
      // )} */}
    </Box>
  );
};

export const RatingSummary = ({ ratings }: { ratings: RatingType[] }) => {
  if (!ratings || ratings.length === 0) {
    return null;
  }

  // Filter out null rates
  const validRatings = ratings.filter((rating) => rating.rate !== null);

  // Initialize count object
  const countByRate: { [key: number]: number } = {};

  // Count occurrences of each rate
  validRatings.forEach(({ rate }) => {
    if (rate !== null) {
      countByRate[rate] = (countByRate[rate] || 0) + 1;
    }
  });

  const icons = [angryIcon, dislikeIcon, likeIcon, happyIcon, heartIcon];
  const colors = ["#C73764", "#DA5A81", "#F17D99", "#F89CB3", "#fd0841"]; // Define colors

  return (
    <Box style={{ display: "flex", justifyContent: "end" }}>
      {icons.map((icon, index) => {
        const rateCount = countByRate[index + 1];
        return rateCount ? (
          <Box key={index} style={{ position: "relative", display: "flex" }}>
            <IconButton style={{ color: colors[index], padding: "5px" }}>
              <img
                src={icon}
                alt={`Rate ${index + 1}`}
                style={{ width: "2rem" }}
              />
            </IconButton>
            {rateCount > 1 && (
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  top: "-5px",
                  right: "-5px",
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "50%",
                }}
              >
                {rateCount}
              </span>
            )}
          </Box>
        ) : null;
      })}
    </Box>
  );
};

function UserRate() {
  return <Box>UserRating</Box>;
}

export default UserRate;
